//! moment.js locale configuration
//! locale : Bambara [bm]
//! author : Estelle Comment : https://github.com/estellecomment

;(function (global, factory) {
   typeof exports === 'object' && typeof module !== 'undefined'
       && typeof require === 'function' ? factory(require('../moment')) :
   typeof define === 'function' && define.amd ? define(['../moment'], factory) :
   factory(global.moment)
}(this, (function (moment) { 'use strict';

// Language contact person : Abdoufata Kane : https://github.com/abdoufata

var bm = moment.defineLocale('bm', {
    months : 'Zanwuyekalo_Fewuruyekalo_Marisikalo_Awirilikalo_Mɛkalo_Zuwɛnkalo_Zuluyekalo_Utikalo_Sɛtanburukalo_ɔkutɔburukalo_Nowanburukalo_Desanburukalo'.split('_'),
    monthsShort : 'Zan_Few_Mar_Awi_Mɛ_Zuw_Zul_Uti_Sɛt_ɔku_Now_Des'.split('_'),
    weekdays : 'Kari_Ntɛnɛn_Tarata_Araba_Alamisa_Juma_Sibiri'.split('_'),
    weekdaysShort : 'Kar_Ntɛ_Tar_Ara_Ala_Jum_Sib'.split('_'),
    weekdaysMin : 'Ka_Nt_Ta_Ar_Al_Ju_Si'.split('_'),
    longDateFormat : {
        LT : 'HH:mm',
        LTS : 'HH:mm:ss',
        L : 'DD/MM/YYYY',
        LL : 'MMMM [tile] D [san] YYYY',
        LLL : 'MMMM [tile] D [san] YYYY [lɛrɛ] HH:mm',
        LLLL : 'dddd MMMM [tile] D [san] YYYY [lɛrɛ] HH:mm'
    },
    calendar : {
        sameDay : '[Bi lɛrɛ] LT',
        nextDay : '[Sini lɛrɛ] LT',
        nextWeek : 'dddd [don lɛrɛ] LT',
        lastDay : '[Kunu lɛrɛ] LT',
        lastWeek : 'dddd [tɛmɛnen lɛrɛ] LT',
        sameElse : 'L'
    },
    relativeTime : {
        future : '%s kɔnɔ',
        past : 'a bɛ %s bɔ',
        s : 'sanga dama dama',
        ss : 'sekondi %d',
        m : 'miniti kelen',
        mm : 'miniti %d',
        h : 'lɛrɛ kelen',
        hh : 'lɛrɛ %d',
        d : 'tile kelen',
        dd : 'tile %d',
        M : 'kalo kelen',
        MM : 'kalo %d',
        y : 'san kelen',
        yy : 'san %d'
    },
    week : {
        dow : 1, // Monday is the first day of the week.
        doy : 4  // The week that contains Jan 4th is the first week of the year.
    }
});

return bm;

})));
